<template>
    <div class="list_dian">
        <div class="tit_dian"> <span> 近期输入：</span> <img @click="closeDain" src="../assets/close.png" alt="" srcset=""></div>
        <ul>
            <li v-for="item in mdata" :key="item" @click="setTypeVal(item)">
              <a href="javascript:void(0);"> {{item}}</a> 
            </li>
        </ul>
        
    </div>
</template>

<script>
export default {
    props: {
        mdata:{
            type: Array,
            required: true,
            default: []
        }
    },
    data() {
        return {
            
        }
    },
    methods: {
        setTypeVal(val){
            console.log("item",val);
            this.$emit("setVal",val)
            this.closeDain()
        },
        closeDain(){
            this.$emit("closeDain")
        }
    }
}
</script>

<style scoped>
ul,li{
    list-style: none;
    padding: 0;
    margin: 0;
}
li{
    line-height: 1.7;
    padding: 2px 8px;
    border-bottom: 1px solid #ededed;
    text-align: center;
    width:100%
}
.tit_dian{
    display:flex;
    justify-content:space-between;
    align-items:center;
    background-color: #eee

}
.tit_dian img{
    width:20px;
    margin: 5px;
}
.tit_dian span{
    font-weight: bold;
    font-size: 15px;
    padding: 6px 3px;
    color: #666;
}
.list_dian {
    position: fixed;
    bottom: 100px;
    left: 1%;
    z-index: 99;
    width:98%;
    background-color: #fff;
    box-shadow: 0 0 3px #ccc;
    max-height: 50vh;
    overflow: hidden;
    overflow-y: auto;
}
.list_dian ul{  
    padding:2px 12px;
}
.list_dian ul li a{
    display: block;
    padding: 8px
}
</style>
